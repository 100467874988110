import getDeliveryPoint from './get-delivery-point';

export default function getTariffs(data) {
  let result = {
    validTariffs: [],
    invalidTariffs: [],
    pointTariffs: [],
    tariffs: [],
  }
  if (!data) {
    return result;
  }

  result.validTariffs = Array.isArray(data) ? data : [data];

  result.validTariffs.forEach(function(tariff) {
    if (tariff.deliveryType === 'deliveryToPoint' && (tariff.deliveryCost.value === 0 || tariff.maxDays === 0 || tariff.maxDays === 0)) {
      result.invalidTariffs.push(tariff.recipient.deliveryPoint);
    }
  });

  result.validTariffs.forEach(function(tariff) {
    if (tariff.state !== 1) {
      if (tariff.deliveryType !== 'deliveryToPoint') {
        result.tariffs.push(tariff);
      } else {
        result.pointTariffs.push(tariff);
      }
    }
  });

  if (result.pointTariffs.length > 0) {
    const point = getDeliveryPoint(result.pointTariffs);
    result.tariffs.push(point);
  }

  return result;
}