import React, {Component} from 'react';
import {connect} from 'react-redux';
import marketApi from '../../../../services/market';
import {setUser} from '../../../../redux/actions/user';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import QRCode from 'qrcode.react';
import Grid from '@material-ui/core/Grid';

class QR extends Component {
  state = {
    loaded: false,
    code: "",
  }

  componentDidMount() {
    marketApi('users/qr')
      .then(json => {
       
        const code = json.data || "";

        this.interval = setInterval(() => this.checkAuth(code), 2000);

        this.setState({
          code,
          loaded: true,
        });
      })
      .catch(error => {
        this.setState({loaded: true});
      })
  }

  checkAuth = (code) => {
    marketApi(`users/qr/check?code=${code}`)
      .then(json => {
        const result = json.data || {};
        if (result.token) {
          this.props.setUser({token: result.token, user_id: result.user_id});
        }
      });
    
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const {loaded, code} = this.state;

    if (!loaded) {
      return null;
    }

    return (
      <React.Fragment>
        <DialogContent>
          <Grid container style={{paddingTop: 20}}>
            <Grid item xs={12} alignItems='center' justify='center' container>
              {
                code && (
                  <QRCode 
                    value={code}
                    renderAs="svg" size={256} 
                  />
                )
              }
              
            </Grid>
          </Grid>
          
        </DialogContent>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => 
  ({
    setUser(value) {
      dispatch(setUser(value));
    },
  });

export default connect(null, mapDispatchToProps)(QR);