import React, {Component} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import PhoneComponent from '../sign-in/phone';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  buttonIcon: {
    margin: theme.spacing.unit,
  },
});

class SignUp extends Component {
  render() {
    const {classes, handleClose, handleView} = this.props;

    return (
      <React.Fragment>
        <Grid container>
          <Grid item lg={8} md={8} xs={9} alignItems='center' container>
            <DialogTitle id="form-dialog-title">Регистрация</DialogTitle>
          </Grid>
          <Grid item lg={4} md={4} xs={3} justify='flex-end' container>
            <IconButton onClick={handleClose} className={classes.buttonIcon} aria-label="Close">
              <ClearIcon fontSize="large"/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <PhoneComponent handleView={handleView}/>
      </React.Fragment>
    )
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignUp);