import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import dadataApi from '../../../../../../services/dadata';
import styles from './styles';

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      // error={props.getValue().length === 0}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class HouseComponent extends React.Component {
  loadOptions = (query, callback) => {
    const {street_fias_id} = this.props;

    const params = {
      query,
      locations: [{
        street_fias_id,
      }],
      from_bound: {
        value: "house"
      }, 
      restrict_value: true
    }

    dadataApi('address', params, 'POST')
      .then(json => {
        const result = json.suggestions || [];

        callback(result.map(sug => {
          const {value, data} = sug
          return {
            value,
            label: value,
            index: data.postal_code,
            city: data.city,
            data,
          };
        }))
      })
  }

  render() {
    const { defaultValue, classes, theme, handleChange, street_fias_id } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <AsyncSelect
            defaultValue={defaultValue}
            classes={classes}
            cacheOptions={true}
            styles={selectStyles}
            textFieldProps={{
              label: 'Дом',
              InputLabelProps: {
                shrink: true,
              },
            }}
            isDisabled={!street_fias_id}
            onChange={handleChange}
            components={components}
            blurInputOnSelectboolean={true}
            placeholder="Номер дома"
            noOptionsMessage={() => "Ничего не найдено"}
            loadingMessage={() => "Идет загрузка"}
            loadOptions={this.loadOptions}
          />
        </NoSsr>
      </div>
    );
  }
}



HouseComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(HouseComponent);
