import React, {Component} from 'react';
import Phone from '../forms/phone';
import Code from '../forms/code';

class PhoneComponent extends Component {
  state = {
    view: 'phone',
  }


  handleChangeView = (view) => {
    this.setState({view});
  }

  render() {
    const {handleView} = this.props;
    return (
      <React.Fragment>
        {
          
          this.state.view === 'phone' ? (
            <Phone handleChangeView={this.handleChangeView} handleView={handleView}/>
          ) : (
            <Code handleChangeView={this.handleChangeView} handleView={handleView}/>
          )
        }
      </React.Fragment>
    )
  }
}

export default PhoneComponent;