import {config} from './config';

export default (endPoint, payload = {}, method = 'GET', headers = {}) => {
  let params = {
    method,
  };

  let newHeaders = new Headers(headers);
  newHeaders.append('Content-Type', 'application/json');
  newHeaders.append("Authorization", `Token ${config.apiKey}`);

  params.headers = newHeaders;

  if (method !== 'GET') {
    params.body = JSON.stringify(payload);
  }

  return fetch(`${config.url}${endPoint}`, params)
    .then(response => {

      if (response.status === 401) { throw new Error("Неверный логин/пароль"); }
      if (response.status !== 200 && response.status !== 204) { throw new Error(""); }

      return response.json();
    })
    .catch((e) => {
      throw e.toString();
    });
}