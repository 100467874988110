import formatDeliveryCosts from './format-delivery-costs';

export default function getDeliveryPoint(points) {

  if (points.length === 0) {
    return [];
  }

  var firstPoint = points[0];

  var result = {
    deliveryType: 'deliveryToPoint',
    minDays: firstPoint.minDays,
    maxDays: firstPoint.maxDays,
    minCost: firstPoint.deliveryCost.value,
    maxCost: firstPoint.deliveryCost.value,
    deliveryCost: {
      value: 0,
      currency: 'RUB'
    }
  };

  points.forEach(function(point) {
    if (point.deliveryCost && point.deliveryCost.value) {

      if (parseFloat(point.deliveryCost.value) < result.minCost) {
        result.minCost = point.deliveryCost.value;
      }

      if (parseFloat(point.deliveryCost.value) > result.maxCost) {
        result.maxCost = point.deliveryCost.value;
      }

      if (point.minDays < result.minDays) {
        result.minDays = point.minDays;
      }

      if (point.maxDays > result.maxDays) {
        result.maxDays = point.maxDays;
      }
    }
  })

  result.deliveryCost.value = formatDeliveryCosts(result.minCost, result.maxCost, points);

  return result;
}