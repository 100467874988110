import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setUser} from '../../../../redux/actions/user';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import getDeliveryCost from '../../../../tools/get-delivery-cost';

class FooterComponent extends Component {
  render() {
    const {
      handleSubmit, 
      classes, 
      isValid, 
      deliveryType,
      deliveryPointId,
      pointTariffs,
      tariffs
    } = this.props;

    return (
      <React.Fragment>
        <Divider/>
        <DialogActions style={{margin: 20}}>
          <Grid container>
            <Grid alignItems='center' item md={6} sm={6} xs={9} lg={6} container>
              {
                deliveryType && <DialogContentText>Стоимость доставки: {getDeliveryCost(deliveryType, deliveryPointId, pointTariffs, tariffs)} ₽</DialogContentText>
              }
            </Grid>
            <Grid justify='flex-end' item md={6} sm={6} xs={3} lg={6} container>
              <Button variant='contained' color='secondary' onClick={() => this.props.setUser({token: "", user_id: "", phone: ""})} className={classes.button}>
                Выйти
              </Button>
              {' '}
              <Button disabled={!isValid()} variant='contained' color='primary' onClick={handleSubmit} className={classes.button}>
                Готово
              </Button>
            </Grid>
          </Grid>
          
        </DialogActions>
      </React.Fragment>
    )
  }
}

FooterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => 
  ({
    setUser(payload) {
      dispatch(setUser(payload))
    },
  })

export default connect(null, mapDispatchToProps)(withStyles(styles)(FooterComponent));