import React, {Component} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import PhoneComponent from './phone';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import QRComponent from './qr';

const styles = theme => ({
  buttonIcon: {
    margin: theme.spacing.unit,
  },
});

class SignIn extends Component {
  state = {
    auth: 'phone',
  }


  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  render() {
    const {classes, handleClose, handleView} = this.props;
    const {auth} = this.state

    return (
      <React.Fragment>
        <Grid container>
          <Grid item lg={8} md={8} xs={9} alignItems='center' container>
            <DialogTitle id="form-dialog-title">Авторизация</DialogTitle>
          </Grid>
          <Grid item lg={4} md={4} xs={3} justify='flex-end' container>
            <IconButton onClick={handleClose} className={classes.buttonIcon} aria-label="Close">
              <ClearIcon fontSize="large"/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid container style={{paddingTop: 20}}>
          <Grid item lg={12} md={12} xs={12} alignItems='center' justify='center' container>
            <RadioGroup
              row
              name="auth"
              className={classes.group}
              value={auth}
              onChange={this.handleChange}
            >
              <FormControlLabel value="phone" control={<Radio />} label="Номер телефона" />
              <FormControlLabel value="qr" control={<Radio />} label="QR код" />
            </RadioGroup>
          </Grid>
        </Grid>
        {
        
          auth === 'phone' ? (
            <PhoneComponent handleView={handleView}/>
          ) : (
            <QRComponent/>
          )
          }
      </React.Fragment>
    )
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);