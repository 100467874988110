
export default function averagePointCosts (points) {
  let total = 0;
  let count = 0;

  points.forEach(tariff => {
    if (tariff.deliveryCost && tariff.deliveryCost.value && tariff.deliveryCost.value != 0) {
      count = count + 1;
      total = total + tariff.deliveryCost.value;
    }
  });

  return total > 0 && total / count || 0
}