import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';

class DetailsComponent extends Component {
  render() {
    const {
      name,
      phone,
      email,
      handleChange, 
    } = this.props;

    return (
      <Grid container spacing={24}>
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            margin="dense"
            name="name"
            value={name}
            error={!name}
            label="Фамилия и имя *"
            placeholder="Петров Александр"
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange} 
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <InputMask mask="79999999999" value={phone} onChange={handleChange}>
            {
              inputProps => (
                <TextField 
                  {...inputProps}
                  error={!/7\d{10}/.test(phone)}
                  margin="dense"
                  name="phone"
                  label="Телефон *"
                  placeholder="71235678903"
                  fullWidth  
                  type="tel" 
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ) 
            }
          </InputMask>
        </Grid>
        <Grid item lg={4} md={4} xs={12}> 
          <TextField
            margin="dense"
            name="email"
            value={email}
            error={!email}
            label="Эл. адрес *"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="user@example.com"
            type="email"
            fullWidth
            onChange={handleChange} 
          />
        </Grid>
      </Grid>
    )
  }
}

DetailsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailsComponent);