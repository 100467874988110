import {config} from './config';

export default (endPoint, payload = {}, method = 'GET', headers = {}) => {
  let params = {
    method,
  };

  let newHeaders = new Headers(headers);

  newHeaders.append('Content-Type', 'application/json');

  params.headers = newHeaders;

  if (method !== 'GET') {
    params.body = JSON.stringify(payload);
  }

  return fetch(`${config.url}${endPoint}`, params)
    .then(response => {

      if (response.status === 401) { throw new Error("Неверный логин/пароль"); }
      if (response.status !== 200) { throw new Error(""); }

      return response.json();
    })
    .then(json => {

      if (json.state && json.state != '0') { throw new Error(json.description); }
      if (json.error) { throw json.description; }
      // if (json.tariffs && json.tariffs.filter(t => { return t.state == 1 }).length > 0) { throw json.tariffs[0].description; }

      return json;
    })
    .catch((e) => {
      throw e.toString();
    });
}
