import React, {Component} from 'react';
import PropTypes from 'prop-types';
import marketApi from '../../../../services/market';
import {connect} from 'react-redux';
import {setUser} from '../../../../redux/actions/user';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import ReactCodeInput from 'react-code-input';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';

const MAX_CODE = 6;

const styles = theme => ({
  button: {
    marginTop: 15,
  },
});

class Code extends Component {

  state = {
    value: "",
    loaded: true,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value && this.state.value.length === MAX_CODE) {
      this.handleSubmit()
    }
  }

  handleChange = (value) => {
    this.setState({value});
  }

  handleSubmit = () => {
    const {phone} = this.props.user;
    const {value} = this.state;

    this.setState({loaded: false});
    marketApi('users', {phone, access_code: value}, 'POST')
      .then(json => {
        const {user_id, token} = json.data;

        this.props.setUser({token, user_id});
        this.setState({loaded: true});
      })
      .catch(e => {
        this.setState({loaded: true});
      });
  }

  render() {
    const {value} = this.state;

    return (
      <React.Fragment>
        <DialogContent>
          <Typography variant="h6" component="h6" align='center' style={{marginTop: 15}}>
            Код проверки
          </Typography>
          <FormGroup row>
            <ReactCodeInput style={{margin: '0 auto', marginTop: 15}} type='text' fields={MAX_CODE} value={value} onChange={this.handleChange} />
          </FormGroup>
        </DialogContent>
      </React.Fragment>
    )
  }
}

Code.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  ({
    setUser(token) {
      dispatch(setUser(token));
    },
  })

const mapStateToProps = state =>
  ({
    user: state.user,
  })

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Code));