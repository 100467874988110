import React, {Component} from 'react';
import './styles.css';

class PointsComponent extends Component {
  componentDidMount() {
    this.initWidget();
  }

  initWidget = () => {
    const {points, city, handleSelect, handleClose} = this.props;

    const widgetMap = new window.aWidgetMap('widget_map', {
      search: city,
      points: points.map(point => point.recipient.deliveryPoint),
      disableQuery: true,
      onCancel() {},
      onSelect(_point) {
        const {name, id} = _point;
        handleSelect(id, name);
      }
    });
    widgetMap.init();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deliveryType === 'deliveryToPoint' && nextProps.deliveryPointId === '') {
      document.getElementById('widget_map').hidden = false;
    } else {
      document.getElementById('widget_map').hidden = true;
    }
  }
  
  render() {
    return (
      <div id="widget_map"></div>
    )
  }
}

export default PointsComponent;