export const DELIVERY_TYPES = {
  "deliveryMagistral": {full: 'Магистральная доставка', short: ''},
  "deliveryToDoor": {full: 'Курьерская доставка', short: 'КД'},
  "deliveryToPoint": {full: 'Пункт выдачи', short: 'ПВ'},
  "deliveryExpress": {full: 'Экспресс доставка', short: 'ЭД'},
  "deliveryRPB": {full: 'Почта России (бандероль)', short: 'ПРБ'},
  "deliveryRPB1": {full: 'Почта России (бандероль 1-й класс)', short: 'ПРБ1К'},
  "deliveryRP": {full: 'Почта России', short: 'ПР'},
  "deliveryRPP": {full: 'Почта России (посылка)', short: 'ПРП'},
  "deliveryLHA": {full: 'Крупногабаритная бытовая техника', short: 'КБТ'}
};