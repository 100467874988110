import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import CityComponent from './components/city';
import StreetComponent from './components/street';
import HouseComponent from './components/house';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

class AddressForm extends Component {
  render() {
    const {
      classes,
      isShow, 
      city,
      city_fias_id,
      settlement_fias_id,
      street,
      street_fias_id,
      house,
      apartment,
      addresses,
      handleCity,
      handleStreet,
      handleHouse,
      handleApartment,
      handleSubmit,
      handleCancel,
    } = this.props;

    if (!isShow) {
      return null;
    }

    return (
      <React.Fragment>
        <Grid container spacing={8}>
          <Grid item xs={12} md={3} lg={3}>
            <CityComponent
              city={city}
              handleChange={handleCity}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <StreetComponent
              key={`${city}`}
              street={street}
              city_fias_id={city_fias_id}
              settlement_fias_id={settlement_fias_id}
              handleChange={handleStreet}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <HouseComponent
              key={street}
              house={house}
              street_fias_id={street_fias_id}
              handleChange={handleHouse}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              margin="dense"
              name="apartment"
              value={apartment}
              label="Квартира"
              placeholder="141"
              type="text"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleApartment} 
            />
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} md={12} lg={12} container justify="flex-end">
            {
              addresses.length > 0 && (
                <Button variant="contained" color="default" onClick={handleCancel}>
                  Отмена
                </Button>
              )
            }
            {' '}
            <Button disabled={!apartment || !house} variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

AddressForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressForm);