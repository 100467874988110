import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';

class AuthComponent extends Component {
  state = {
    view: '',
  }

  render() {
    const {classes, handleClose, handleView} = this.props;

    return (
      <React.Fragment>
        <Grid container>
          <Grid item lg={9} md={9} xs={9} alignItems='center' container>
            <DialogTitle disableTypography>Имеете ли Вы учетную запись Allibry?</DialogTitle>
          </Grid>
          <Grid item lg={3} md={3} xs={3} justify='flex-end' container>
            <IconButton onClick={handleClose} className={classes.buttonIcon} aria-label="Close">
              <ClearIcon fontSize="large"/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>
          <DialogContent className={classes.content}>
            {
              this.state.view ? (
                <Button></Button>
              ) : (
                <React.Fragment>
                  <Button onClick={() => handleView('sign-in')}  variant="contained" color="primary" className={classes.button}>
                    Да
                  </Button>
                  {" "}
                  <Button onClick={() => handleView('sign-up')}  variant="contained" color="primary" className={classes.button}>
                    Нет
                  </Button>
                </React.Fragment>
              )
            }
          </DialogContent>
      </React.Fragment>
    )
  }
}

AuthComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AuthComponent);