import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setPhone} from '../../../../redux/actions/user';
import marketApi from '../../../../services/market';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  button: {
    marginTop: 15,
  },
});

class Test extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
      valid: false,
      value: ""
    };
  }

  handleChange = (event) => {
    const value = event.target.value;
    const valid = /7\d{10}/.test(value);
    this.setState({
      valid,
      value,
    });
  }

  handleSubmit = () => {
    const {value} = this.state;
    // const value = this.state.value
    //   .replace(/\(/, "")
    //   .replace(/\)/, "")
    //   .replace(/\s/g, "")
    //   .replace(/\+/g, "")
    //   .replace(/-/g, "");

    this.setState({loaded: false});
    marketApi('users', { phone: value }, 'POST')
      .then(json => {

        this.props.setPhone({phone: value, formatedPhone: value});
        this.props.handleChangeView('code');
        
        this.setState({loaded: true});
      })
      .catch(e => {
        this.setState({loaded: true});
      });
  }

  render() {
    const {value, valid} = this.state;
    const {classes, handleView} = this.props;

    return (
      <React.Fragment>
        <DialogContent>
          <InputMask mask="79999999999" value={value} onChange={this.handleChange}>
            {
              inputProps => (
                <TextField 
                  {...inputProps}
                  error={!valid}
                  margin="dense"
                  id="phone"
                  name="phone"
                  label="Телефон"
                  placeholder="71235678903"
                  fullWidth  
                  type="tel" 
                />
              ) 
            }
          </InputMask>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleView('')} variant="contained" color="default" className={classes.button}>
            Отмена
          </Button>
          {' '}
          <Button onClick={this.handleSubmit} disabled={!valid} variant="contained" color="primary" className={classes.button}>
            Готово
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }
}

Test.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => 
  ({
    setPhone(value) {
      dispatch(setPhone(value));
    },
  });

export default connect(null, mapDispatchToProps)(withStyles(styles)(Test));