// import weight from './weight'
// import bulkWeight from './bulk-weight'
import dimensions from './dimensions';
// import totalCost from './total-cost'

export default function calculationParams(index, city, address, products) {
  const {width, length, height} = dimensions();
  // const cost = totalCost(products);

  return {
    calculation: [
      {
        to: {
          deliveryType: "",
          recipient: {
            index,
            city,
            address,
          },
        },
        deliverySalad: false,
        // weight: weight(products),
        weight: 10,
        // bulkWeight: bulkWeight(length, width, height),
        width: width,
        height: height,
        length: length,
        codCost: 1000,
        assessedCost: 1000,
        pickupDate:""
      }
    ]
  }
}