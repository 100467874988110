import React, {Component} from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import AddressForm from '../address';
import marketApi from '../../../../services/market';
import {toast} from 'react-toastify';
import Preloader from '../../../../components/layout/page/preloader';

class AddressListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,

      addresses: [],
      isShow: false,
      address: {
        index: null,

        fias_id: "",

        city: "",
        city_fias_id: null,
        settlement_fias_id: null,

        street_fias_id: null,
        street: "",

        house: "",
        house_fias_id: "",

        apartment: "",
      }
    };
  }

  componentDidMount() {
    this.loadingData();
  }

  loadingData = () => {
    this.setState({loaded: false});
    marketApi(`users/address`)
      .then(json => {
        const addresses = json.data || [];
        
        const current = addresses.find(addr => addr.default);

        if (current && !this.props.order) {
          this.props.handleChange(current);
        }

        this.setState({
          isShow: addresses.length === 0 ? true : this.state.isShow,
          addresses,
          loaded: true,
        });
      })
      .catch(e => {
        toast.error(e);
        this.setState({loaded: true});
      });
  }

  handleSubmit = () => {
    const {address} = this.state;
    marketApi(`users/address`, address, 'POST')
      .then(json => {
        this.setState({isShow: false});
        this.loadingData();
      })
      .catch(e => {
        toast.error(e);
        this.setState({loaded: false});
      });
  }

  handleCancel = () => {
    this.setState({isShow: false});
  }

  handleCity = (city, event) => {
    if (event.action === "select-option") {
      const {value, index, data: {city_fias_id, settlement_fias_id}} = city;

      this.setState({
        address: {
          ...this.state.address,
          index,
          city: value,
          city_fias_id,
          settlement_fias_id,
          street: "",
          street_fias_id: null,
          house: "",
          apartment: "",
        },
      });
    }
  }

  handleStreet = (street, event) => {
    if (event.action === "select-option") {
      const {value, data: {fias_id}} = street;

      this.setState({
        address: {
          ...this.state.address,
          house: "",
          street: value,
          street_fias_id: fias_id,
        },
      });
    }
  }

  handleHouse = (house, event) => {
    if (event.action === "select-option") {
      const {value, index} = house;
      this.setState({
        address: {
          ...this.state.address,
          index,
          house: value
        },
      })
    }
  }

  handleApartment = (event) => {
    const {name, value} = event.target;
    this.setState({
      address: {
        ...this.state.address,
        [name]: value,
      },
    });
  }

  getAddressKey = (address) => {
    const {
      city,
      street,
      house,
      apartment,
    } = address;

    return `${city}, ${street}, ${house}, ${apartment}`;
  }

  render() {
    const {classes, handleChange, current} = this.props;
    const {
      loaded,
      isShow,
      addresses,
      address: {
        city,
        city_fias_id,
        settlement_fias_id,
        street,
        street_fias_id,
        house,
        apartment,
      }
    } = this.state;

    return (
      <div className={classes.root}>
        <Preloader loaded={loaded}>
          <List
            component="nav"
            subheader={
              <ListSubheader component={'li'} disableGutters disableSticky >
                {
                  addresses.length > 0 && (
                    <Grid container>
                      <Grid item lg={8} md={8} xs={7} alignItems='center' container>
                        Выберите адрес доставки
                      </Grid>
                      <Grid item lg={4} md={4} xs={5} justify='flex-end' container>
                        <Button color="primary" size="small" onClick={() => this.setState({isShow: true})}>
                          <AddIcon/>
                          {' '}
                          адрес
                        </Button>
                      </Grid>
                    </Grid>
                  )
                }
                
                <AddressForm 
                  isShow={isShow} 
                  addresses={addresses}
                  city={city}
                  city_fias_id={city_fias_id}
                  settlement_fias_id={settlement_fias_id}
                  street={street}
                  street_fias_id={street_fias_id}
                  house={house}
                  apartment={apartment}
                  handleCity={this.handleCity}
                  handleStreet={this.handleStreet}
                  handleHouse={this.handleHouse}
                  handleApartment={this.handleApartment}
                  handleCancel={this.handleCancel}
                  handleSubmit={this.handleSubmit}
                />
              </ListSubheader>
            }
          >
            {
              addresses.map(item => {
                const {city, street, house, apartment} = item;
                return (
                  <ListItem disableGutters divider key={item.id}>
                    <ListItemText primary={`${city}, ${street}, ${house}, ${apartment}`}/>
                    
                    <ListItemSecondaryAction>
                      {
                        item.default && <Chip label="По-умолчанию" className={classes.chip} />
                      }
                      <Radio value={item.id.toString()} name="currentAddress" onChange={() => handleChange(item)} checked={current && this.getAddressKey(current) == this.getAddressKey(item)}/>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              }
              )
            }
          </List>
        </Preloader>
      </div>
    )
  }
}

AddressListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressListComponent);