const styles = theme => ({
  root: {
    // marginTop: theme.spacing.unit * 3,
    overflow: 'none',
    marginBottom: 20,
  },
  head: {
    height: '33px !important',
  },
});

export default styles;