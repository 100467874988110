import averagePointCosts from './average-point-costs';

export default function formatDeliveryCosts(min, max, points) {
  if (min == max && min == 0) {
    return '0';
  }

  if (min == max) {
    return min;
  }

  if (min == 0) {
    return `~ ${averagePointCosts(points)}`
  }

  return `${min} - ${max}`;
}