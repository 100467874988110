import {SET_TOKEN, SET_PHONE, REMOVE_PHONE} from '../constants';

export const setUser = (payload) =>
  ({
    type: SET_TOKEN,
    payload,
  });

export const setPhone = (payload) =>
  ({
    type: SET_PHONE,
    payload,
  });

export const removePhone = () =>
  ({
    type: REMOVE_PHONE,
  });
