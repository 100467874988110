export default function getDeliveryCost(deliveryType, deliveryPointId, pointTariffs, tariffs) {
  let deliveryCost = 0;

  if (deliveryType === 'deliveryToPoint') {
    let point = pointTariffs.find(function(p) {
      return p.recipient.deliveryPoint == deliveryPointId;
    });
    deliveryCost = point && point.deliveryCost && point.deliveryCost.value || 0;
  } else {
    let tariff = tariffs.find(function(t){return t.deliveryType == deliveryType})
    deliveryCost = tariff && tariff.deliveryCost && tariff.deliveryCost.value || 0;
  }

  return parseFloat(deliveryCost);
}