import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import isEqual from 'lodash/isEqual';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {DELIVERY_TYPES} from '../../../../constants';
import formatDeliveryDays from '../../../../tools/format-delivery-days';
import calculationParams from '../../../../tools/calculation-params';
import getTariffs from '../../../../tools/get-tariffs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import publicApi from '../../../../services/public';
import {toast} from 'react-toastify';
import Preloader from '../../../../components/layout/page/preloader';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const gunnarStyle = { height: "33px"};
const gunnarCellStyle = { height: "33px", paddingLeft: '0px', paddingRight: '0px'};


class DeliveryTypesComponent extends React.Component {
  state = {
    loaded: false,
    tariffs: [],
    pointTariffs: [],
  }

  componentDidMount() {
    this.calculate(this.props);
  }


  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.current, nextProps.current) && nextProps.current) {
      this.calculate(nextProps);
    }
  }

  calculate = (props) => {
    const {apikey, products, current: {index, address}} = props;

    this.setState({loaded: false});
    publicApi('calculator2', calculationParams(index, address, address, products), "POST", {Apikey: apikey})
      .then(json => {
        const result = json || {};

        this.setState({loaded: true});

        if (result.error) {
          toast.error(result.description || 'Не удалось рассчитать доставку')
          return;
        }

        const {tariffs, pointTariffs} = getTariffs(result.tariffs);

        this.props.handleTariff(tariffs, pointTariffs);

        if (tariffs.length === 0) {
          toast.error('Не удалось рассчитать доставку')
        }

        return;
      })
      .catch(e => {
        toast.error('Сервис временно недоступен')
        this.setState({loaded: true})
      })
  }
  
  render() {
    const {classes, value, handleChange, tariffs} = this.props;
    const {loaded} = this.state;

    return (
      <Preloader loaded={loaded}>
        <div className={classes.root}>
          <Grid item xs={12}>
            <Table style={{ tableLayout: 'auto' }} padding='dense' className={classes.table}>
            <TableHead>
              <TableRow style={gunnarStyle}>
                <CustomTableCell style={gunnarStyle}>Услуга</CustomTableCell>
                <CustomTableCell style={gunnarStyle}>Срок доставки, дней</CustomTableCell>
                <CustomTableCell style={gunnarStyle}>Стоимость, ₽</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tariffs.map((tariff, index) => {
                return (
                  <TableRow key={index} style={gunnarStyle}>
                    <TableCell style={gunnarCellStyle}>
                      <RadioGroup
                        aria-label="Gender"
                        name="deliveryType"
                        className={classes.group}
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel value={tariff.deliveryType} control={<Radio style={{paddingTop: '0px', paddingBottom: '0px'}} color="primary"/>} label={DELIVERY_TYPES[tariff.deliveryType] && DELIVERY_TYPES[tariff.deliveryType].full} />
                      </RadioGroup>
                    </TableCell>
                    <TableCell style={gunnarCellStyle}>{formatDeliveryDays(tariff.minDays, tariff.maxDays)}</TableCell>
                    <TableCell style={gunnarCellStyle}>{tariff.deliveryCost && tariff.deliveryCost.value}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            </Table>
          </Grid>
        </div>
      </Preloader>
    );
  }
}

DeliveryTypesComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeliveryTypesComponent);
