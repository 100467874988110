import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import {setUser} from '../../redux/actions/user';
import getDeliveryCost from '../../tools/get-delivery-cost';
import marketApi from '../../services/market';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './styles';
import HeaderComponent from './components/header';
import FooterComponent from './components/footer';
import AddressListComponent from './components/address-list';
import DeliveryTypesComponent from './components/delivery-types';
import DeliveryDateComponent from './components/delivery-date';
import PointsComponent from './components/points';
import DetailsComponent from './components/details';
import {toast} from 'react-toastify';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class CardComponent extends Component {
  state = {
    address: null,

    name: '',
    phone: '',
    email: '',
    description: "",

    tariffs: [],
    pointTariffs: [],

    deliveryType: null,
    deliveryPointName: "",
    deliveryPointId: "",

    deliveryDate: moment(),
    deliveryTimeFrom: moment(`${moment().utc().format('YYYY-MM-DD')} 09:00`),
    deliveryTimeTo: moment(`${moment().utc().format('YYYY-MM-DD')} 18:00`),

    guids: [],

    loaded: false,
  }

  componentDidMount() {
    const {order} = this.props;

    if (order) {
      this.loadingOrder(order);
    } else {
      this.loadingUser();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevAddress = prevState.address && `${prevState.address.city}, ${prevState.address.street}, ${prevState.address.house}, ${prevState.address.apartment}` || null;
    const curAddress = this.state.address && `${this.state.address.city}, ${this.state.address.street}, ${this.state.address.house}, ${this.state.address.apartment}` || null;

    if (prevAddress && !isEqual(curAddress, prevAddress)) {
      this.setState({deliveryPointId: '', deliveryPointName: '', deliveryType: null})
    }
  }

  loadingUser = () => {
    const {user_id} = this.props.user;
    marketApi(`users/${user_id}`)
      .then(json => {
        const result = json.data || {};
        const {fio: name, phone, email} = result;
        this.setState({name, phone, email, loaded: true});
      })
      .catch(e => {
        toast.error(e);
        this.setState({loaded: true});
      });
  }

  loadingOrder = (order) => {
    marketApi(`orders/${order.id}`)
      .then(json => {
        const result = json.data || {};
        const {
          delivery_type: deliveryType, 
          delivery_point_id: deliveryPointId, 
          delivery_point_name: deliveryPointName, 
          delivery_date: deliveryDate,
          delivery_time_from: deliveryTimeFrom,
          delivery_time_to: deliveryTimeTo,
          name, 
          phone,
          email,
          guids,
          index,
          fias_id,
          city,
          city_fias_id,
          settlement_fias_id,
          street,
          street_fias_id,
          house,
          house_fias_id,
          apartment,
          description,
        } = result;

        debugger

        this.setState({
          address: {
            index,
            fias_id,
            city,
            city_fias_id,
            settlement_fias_id,
            street,
            street_fias_id,
            house,
            house_fias_id,
            apartment,
          },
          deliveryType, 
          deliveryPointId, 
          deliveryPointName, 
          deliveryDate,
          deliveryTimeFrom: moment(`${moment().utc().format('YYYY-MM-DD')} ${moment(deliveryTimeFrom).utc().hour()}:${moment(deliveryTimeFrom).utc().minute()}`),
          deliveryTimeTo: moment(`${moment().utc().format('YYYY-MM-DD')} ${moment(deliveryTimeTo).utc().hour()}:${moment(deliveryTimeTo).utc().minute()}`),
          email, 
          name,
          phone,
          guids,
          description,
        });
      })
      .catch(e => {
        toast.error(e);
        this.setState({loaded: true});
      });
  }

  handleAddress = (address) => {
    this.setState({address});
  }

  handleDelivery = (event) => {
    const {value} = event.target;
    this.setState({
      deliveryType: value,
    });
  }

  handlePoint = (deliveryPointId, deliveryPointName) => {
    this.setState({deliveryPointId, deliveryPointName});
  }

  handleDate = (name, value) => {
    this.setState({[name]: value});
  }

  getOrderParams = (deliveryCost) => {
    const {
      name, 
      phone, 
      email,
      description,
      deliveryType, 
      deliveryPointId, 
      deliveryPointName, 
      deliveryDate,
      deliveryTimeFrom,
      deliveryTimeTo,
      address,
    } = this.state;

    const {products, apikey} = this.props;
    const {
      index, 
      fias_id,
      city,
      city_fias_id,
      settlement_fias_id,
      street,
      street_fias_id,
      house,
      house_fias_id,
      apartment,
    } = address;

    return {
      cart: products.map(p => {
        return {
          product_id: p.id,
          count: p.count,
        }
      }),
      delivery: [
        {
          api_key: apikey,
          cost: deliveryCost
        }
      ],
      description,
      delivery_type: deliveryType,
      delivery_point_id: deliveryType === 'deliveryToPoint' ? deliveryPointId : null,
      delivery_point_name: deliveryType === 'deliveryToPoint' ? deliveryPointName : "",
      payment_type: "cash",
      index: deliveryType !== 'deliveryToPoint' ? index : "",
      address: deliveryType !== 'deliveryToPoint' ? `${city} ${street} ${house}` : "",
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
      delivery_time_from: `0001-01-01T${deliveryTimeFrom.format('HH:mm:ss')}`,
      delivery_time_to: `0001-01-01T${deliveryTimeTo.format('HH:mm:ss')}`,
      name,
      phone,
      email,
      index, 
      fias_id,
      city,
      city_fias_id,
      settlement_fias_id,
      street,
      street_fias_id,
      house,
      house_fias_id,
      apartment,
      draft: true,
    }
  }

  handleSubmit = () => {
    const {
      deliveryType,
      deliveryPointId,
      deliveryPointName,
      deliveryDate,
      deliveryTimeFrom,
      deliveryTimeTo,
      address,
      name,
      phone,
      email,
      description,
      tariffs,
      pointTariffs,
    } = this.state;

    const deliveryCost = getDeliveryCost(deliveryType, deliveryPointId, pointTariffs, tariffs);

    const {order, user} = this.props;

    marketApi(`orders${order ? `/${order.id}` : ''}`, this.getOrderParams(deliveryCost), `${order ? 'PUT' : 'POST'}`)
      .then(json => {

        this.setState({loaded: true});

        const result = json.data || {};
        const guid = result.guids[0];

        const params = {
          id: result.id,
          token: user.token,
          deliveryType,
          deliveryPointId,
          deliveryPointName,
          deliveryDate,
          deliveryTimeFrom: `0001-01-01T${deliveryTimeFrom.format('HH:mm:ss')}`,
          deliveryTimeTo: `0001-01-01T${deliveryTimeTo.format('HH:mm:ss')}`,
          deliveryCost,
          amount: guid.total_cost,
          address,
          name,
          phone,
          email,
          description,
        };

        window.parent.postMessage(JSON.stringify({params, name: 'done'}), "*")
      })
      .catch(e => {
        toast.error(e);
        this.setState({loaded: true});
      });
  }

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  isValid = () => {
    const {
      deliveryType,
      deliveryPointId,
      name,
      phone,
      address,
      email,
    } = this.state;

    if (!deliveryType) {
      return false;
    }

    if (!name || !/7\d{10}/.test(phone) || !email) {
      return false;
    }

    if (deliveryType === 'deliveryToPoint') {
      return deliveryPointId;
    } else {
      return address;
    }
  }

  handleTariff = (tariffs, pointTariffs) => {
    this.setState({tariffs, pointTariffs});
  }

  render() {
    const {classes, handleClose, apikey, order, products} = this.props;
    const {
      address,
      name,
      phone,
      email,
      deliveryDate,
      deliveryTimeFrom,
      deliveryTimeTo,
      deliveryType,
      deliveryPointId,
      deliveryPointName,
      tariffs,
      pointTariffs,
    } = this.state;

    return (
      <React.Fragment>
        <HeaderComponent handleClose={handleClose}/>
        <DialogContent className={classes.content}>
          <AddressListComponent 
            key={address}
            current={address}
            order={order}
            handleChange={this.handleAddress}
          />
          {
            address && (
              <React.Fragment>
                <DeliveryTypesComponent 
                  apikey={apikey} 
                  tariffs={tariffs}
                  pointTariffs={pointTariffs}
                  current={address}
                  products={products}
                  value={deliveryType}
                  handleTariff={this.handleTariff}
                  handleChange={this.handleDelivery}
                />
                {
                  deliveryType === "deliveryToPoint" && deliveryPointId && (
                    <Grid container spacing={24} style={{marginTop: 20}}>
                      <Grid alignItems='center' justify='flex-start' item lg={8} md={8} sm={8} xs={12} container>
                        <Typography variant="h6" component="h6">
                          {deliveryPointName}
                        </Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Button fullWidth variant='contained' color='primary' onClick={() => this.setState({deliveryPointId: "", deliveryPointName: ""})}>
                          Выбрать другой пункт
                        </Button>
                      </Grid>
                    </Grid>
                  )
                }
                {
                  deliveryType === 'deliveryToPoint' && !deliveryPointId && (
                    <PointsComponent
                      key={`${deliveryPointId}_${pointTariffs.length}`}
                      handleSelect={this.handlePoint} 
                      points={pointTariffs} 
                      city={address.city} 
                      deliveryPointId={deliveryPointId} 
                      deliveryPointName={deliveryPointName} 
                      deliveryType={deliveryType}
                    />
                  )
                }
                <DeliveryDateComponent
                  deliveryType={deliveryType}
                  deliveryDate={deliveryDate}
                  deliveryTimeFrom={deliveryTimeFrom}
                  deliveryTimeTo={deliveryTimeTo}
                  handleChange={this.handleDate}
                />
              </React.Fragment>
            )
          }
          
          
          <DetailsComponent
            name={name}
            phone={phone}
            email={email}
            handleChange={this.handleChange}
          />
        </DialogContent>
        <FooterComponent 
          deliveryType={deliveryType}
          deliveryPointId={deliveryPointId}
          tariffs={tariffs}
          pointTariffs={pointTariffs}
          handleSubmit={this.handleSubmit} 
          isValid={this.isValid}
        />
      </React.Fragment>
    )
  }
}

CardComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state =>
  ({
    user: state.user,
  })

const mapDispatchToProps = dispatch =>
  ({
    setUser(payload) {
      dispatch(setUser(payload))
    },
  })

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CardComponent));