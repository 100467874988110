import React, { Component } from 'react';
import {connect} from 'react-redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AuthComponent from '../../steps/auth';
import CardComponent from '../../steps/card';
import SignIn from '../../steps/auth/sign-in';
import SignUp from '../../steps/auth/sign-up';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const parseQueryString = (queryString) => {
  var params = {}, queries, temp, i, l;
  queries = queryString.split("&");

  for ( i = 0, l = queries.length; i < l; i++ ) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1];
  }
  return params;
};

const styles = theme => 
  ({
    content: {
      margin: 0
      // minHeight: 400,
      // width: '50vw',
    }
  })

class DialogComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      apikey: "",
      order: null,
      view: '',
    };
  }

  handleClose = () => {
    window.parent.postMessage(JSON.stringify({name: 'close'}), "*");
  };

  handleView = (view) => {
    this.setState({view});
  }

  listener = event => {
    // const origin = 'http://localhost:3013';
    const origin = 'https://webmarket.a1srv.ru';

    if (event.origin != origin) {
      return;
    }

    const {products, order, apikey} = JSON.parse(event.data);

    this.setState({
      products,
      apikey,
      order,
    });
  }

  componentDidMount() {
    if (window.addEventListener) {
      window.addEventListener("message", this.listener);
    } else {
      window.attachEvent("onmessage", this.listener);
    }
  }

  renderAuth = () => {
    const {view} = this.state;

    if (!view) {
      return (
        <AuthComponent handleClose={this.handleClose} handleView={this.handleView}/>
      )
    }

    if (view === 'sign-in') {
      return (
        <SignIn handleClose={this.handleClose} handleView={this.handleView}/>
      )
    }

    if (view === 'sign-up') {
      return (
        <SignUp handleClose={this.handleClose} handleView={this.handleView}/>
      )
    }
  }

  render() {
    const {classes, user, fullScreen} = this.props;
    const {order, apikey, products} = this.state;

    return (
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        disableBackdropClick={true}
        maxWidth = {user.token ? 'lg' : 'xs'}
        style={{margin: '0 !important'}}
        className={classes.content}
        open={true}
        onClose={this.handleClose}
        TransitionComponent={Transition}
        keepMounted
      >
        {
          !user.token ? (
            this.renderAuth()
          ) : (
            <CardComponent 
              handleClose={this.handleClose} 
              order={order}
              apikey={apikey} 
              products={products}
            />
          )
        }
        <ToastContainer/>
      </Dialog>
    )
  }
}


DialogComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired
};

const mapStateToProps = state =>
  ({
    user: state.user,
  })

export default withMobileDialog({breakpoint: 'xs'})(connect(mapStateToProps)(withStyles(styles)(DialogComponent)));