const styles = theme =>
  ({
    root: {

    },
    button: {
      marginLeft: 15,
    },
  });

export default styles;