import {SET_TOKEN, SET_PHONE, REMOVE_PHONE} from '../constants';

const initialState = {
  token: "",
  user_id: "",
  phone: "",
  formatedPhone: "",
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        ...action.payload
      };

    case SET_PHONE:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE_PHONE:
      return {
        ...state,
        phone: initialState.phone,
      };

    default:
      return state;
  }

}

export default user;
