export default function formatDeliveryDays(min, max) {
  if (min == max && min == 0) {
    return 'от 1';
  }

  if (min == max) {
    return min;
  }

  if (min == 0) {
    return `до ${max}`
  }

  return `${min} - ${max}`;
}