import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';

class HeaderComponent extends Component {
  render() {
    const {classes, handleClose} = this.props;

    return (
      <React.Fragment>
        <Grid container>
          <Grid item lg={8} md={8} xs={9} alignItems='center' container>
            <DialogTitle id="form-dialog-title">Параметры доставки</DialogTitle>
          </Grid>
          <Grid item lg={4} md={4} xs={3} justify='flex-end' container>
            <IconButton onClick={handleClose} className={classes.buttonIcon} aria-label="Close">
              <ClearIcon fontSize="large"/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider/>
      </React.Fragment>
    )
  }
}


HeaderComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderComponent);