import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {DatePicker, TimePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import 'moment/locale/fr';
import 'moment/locale/ru';
import MomentUtils from '@date-io/moment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styles from './styles';
import moment from 'moment';

class DeliveryDateComponent extends Component {
  render() {
    const {
      deliveryDate, 
      deliveryTimeFrom, 
      deliveryTimeTo,
      deliveryType,
      handleChange,
    } = this.props;

    if (!deliveryType || deliveryType === 'deliveryRPP') {
      return null;
    }

    return (
      <Grid container spacing={24}>
        <Grid item lg={4} md={4} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
            <DatePicker
              label="Дата доставки"
              name="deliveryDate"
              fullWidth
              value={deliveryDate}
              format={'DD.MM.YYYY'}
              minDate={moment()}
              onChange={(date) => handleChange('deliveryDate', date)}
              leftArrowIcon={<ChevronLeftIcon/>}
              rightArrowIcon={<ChevronRightIcon/>}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
            <TimePicker
              ampm={false}
              label="Время доставки с"
              name="deliveryTimeFrom"
              fullWidth
              value={deliveryTimeFrom}
              format={'HH:mm'}
              onChange={(time) => handleChange('deliveryTimeFrom', time)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
            <TimePicker
              ampm={false}
              label="Время доставки по"
              name="deliveryTimeTo"
              fullWidth
              value={deliveryTimeTo}
              format={'HH:mm'}
              onChange={(time) => handleChange('deliveryTimeTo', time)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    )
  }
}

DeliveryDateComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeliveryDateComponent);