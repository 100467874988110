const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 20,
  },
  selectedPoint: {
    width: '100%',
    margin: '0 auto',
    padding: 15,
    backgroundColor: '#e6e6e7',
  },
  content: {
    height: '60vh',
    paddingTop: 20,
  }
});

export default styles;